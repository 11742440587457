// General project CSS

.container {
  display: flex;
  justify-content: center;
}

.page-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;

  .section {
    max-width: var(--maxWidth-lg);
    width: 100%;
    padding: var(--spacing-12) var(--spacing-48);

    &.main {
      min-height: 400px;
      padding: var(--spacing-8) var(--spacing-48) var(--spacing-24);
      &.small {
        min-height: auto;
      }
    }

    &.thin {
      padding: var(--spacing-12) var(--spacing-64) var(--spacing-24);
      text-align: center;
    }

    &.wider {
      padding: var(--spacing-12) var(--spacing-24) var(--spacing-16);
    }

    &.small-padding-bottom {
      min-height: 300px;
      padding-bottom: var(--spacing-8);
    }

    &.no-padding-top {
      padding-top: var(--spacing-0);
    }
  }
}

img.divider {
  width: 100%;
  max-width: 100%;
  height: 500px;
  object-fit: cover;
}

.row {
  display: flex;
  justify-content: center;

  &.padding-bottom {
    padding-bottom: var(--spacing-8);
  }

  .column {
    flex: 50%;
    padding: var(--spacing-6);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--fontFamilyHeadings);
}

p {
  font-size: var(--fontSize-2);

  &.headline {
    font-size: var(--fontSize-4);
    font-family: var(--fontFamilySubheadings);
  }
}

.button {
  padding: var(--spacing-2) var(--spacing-12);
  background: var(--color-work-3);
  display: inline-block;
  color: var(--color-razem-10);
  font-family: var(--fontFamilyHeadingsBold);
  font-size: var(--fontSize-2);
  border-radius: 10px;
  cursor: pointer;

  &.disabled {
    background: var(--color-gray-400);

    &:hover {
      background: var(--color-gray-400);
    }
  }

  a {
    text-decoration: none;
    color: var(--color-razem-10);
  }

  &:hover {
    background: var(--color-work-2);
  }
}

.highlight {
  text-align: center;
  font-family: var(--fontFamilyHeadings);
  font-size: var(--fontSize-2);
  color: var(--color-work-2);
  background: var(--color-background-work);
  width: 100%;
  padding: var(--spacing-12) var(--spacing-64) var(--spacing-16);

  .heading {
    font-size: var(--fontSize-6);
  }
}

.highlight-simple {
  h2 {
    padding: 0 0 var(--spacing-16) 0;
    margin: 0;
    a {
      text-decoration: underline;
    }
  }
}

@media (max-width: 54rem) {
  img.divider {
    height: auto;
  }

  .main-heading {
    font-size: var(--fontSize-5);
  }

  p.headline {
    font-size: var(--fontSize-3);
  }

  .highlight {
    padding: var(--spacing-12);
  }

  .row {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;

    .column {
      flex: 100%;
    }
  }

  .page-wrapper {
    text-align: center;
    .section {
      max-width: var(--maxWidth-lg);
      width: 100%;
      padding: var(--spacing-12) var(--spacing-4);

      &.main {
        min-height: 400px;
        padding: var(--spacing-6) var(--spacing-12) var(--spacing-12);
      }

      &.thin {
        padding: var(--spacing-12);
        text-align: center;
      }

      &.wider {
        padding: var(--spacing-6);
      }

      &.no-padding-top {
        padding-top: var(--spacing-0);
      }
    }
  }
}
